import React from "react"
import { graphql } from "gatsby"

import { Layout } from "@solublestudio/gatsby-theme-soluble-source"

import Section from "../sections"

export default function Page({ data }) {
    const isHeaderDark =
        data.datoCmsPage.sections &&
        data.datoCmsPage.sections.length &&
        data.datoCmsPage.sections[0].__typename.includes("Hero")

    return (
        <Layout
            isDark={isHeaderDark}
            navbarFixed={true}
            navbarTransparent={true}
        >
            {data.datoCmsPage.sections.map((section, i) => (
                <Section
                    key={i}
                    order={i + 1}
                    isLast={i + 1 === data.datoCmsPage.sections.length}
                    previousSectionType={
                        i ? data.datoCmsPage.sections[i - 1].__typename : null
                    }
                    nextSectionType={
                        data.datoCmsPage.sections.length > i + 1
                            ? data.datoCmsPage.sections[i + 1].__typename
                            : null
                    }
                    {...section}
                />
            ))}
        </Layout>
    )
}

export const query = graphql`
    fragment TextHtml on DatoCmsTextNode {
        childMarkdownRemark {
            html
            htmlWithoutWrapper
        }
    }

    fragment Button on DatoCmsButton {
        detached
        externalLink
        label
        link {
            slug
        }
    }

    fragment Project on DozenProject {
        cashTargetDisplay
        cashDisplay
        textCashInvested
        cardType
        percentageCompleted
        investedText
        remainingDaysText
        name
        description
        href
        taxIncentive
        webId
        slug
        mainImage {
            childImageSharp {
                resize(width: 888, height: 576, cropFocus: CENTER) {
                    src
                }
            }
        }
        status
        currentInvestorsRound
    }

    fragment Company on DozenCompany {
        name
        description
        status
        slug
        logo {
            publicURL
            childImageSharp {
                resize(
                    width: 528
                    height: 264
                    cropFocus: CENTER
                    fit: CONTAIN
                    background: "rgba(255,255,255,1)"
                ) {
                    src
                }
            }
        }
    }

    fragment Point on DatoCmsWhyPoint {
        title
        textNode {
            ...TextHtml
        }
        icon {
            file {
                publicURL
            }
        }
        image {
            file {
                publicURL
                childImageSharp {
                    gatsbyImageData(width: 720)
                }
            }
        }
    }

    fragment Person on DatoCmsPerson {
        image {
            file {
                childImageSharp {
                    resize(width: 740, height: 740, cropFocus: CENTER) {
                        src
                    }
                }
            }
        }
        name
        personPositionNode {
            ...TextHtml
        }
        linkedin
        descriptionNode {
            ...TextHtml
        }
    }

    fragment Faq on DatoCmsFaq {
        question
        answerNode {
            ...TextHtml
        }
    }

    fragment BasicWordpressPost on WpPost {
        excerptWithoutWrapper
        date(formatString: "MMMM DD, YYYY", locale: "es")
        title
        slug
        featuredImage {
            node {
                localFile {
                    childImageSharp {
                        resize(width: 1280, height: 830, cropFocus: CENTER) {
                            src
                        }
                    }
                }
                altText
            }
        }
    }

    query Page($id: String, $language: String) {
        datoCmsPage(id: { eq: $id }, locale: { eq: $language }) {
            sections {
                ... on DatoCmsHeroWithImage {
                    __typename
                    image {
                        file {
                            childImageSharp {
                                resize(width: 1920, height: 1440) {
                                    src
                                }
                            }
                        }
                    }
                    titleNode {
                        ...TextHtml
                    }
                    textNode {
                        ...TextHtml
                    }
                    buttons {
                        ...Button
                    }
                    legalTextNode {
                        ...TextHtml
                    }
                }
                ... on DatoCmsLogo {
                    __typename
                    logos {
                        file {
                            publicURL
                            childImageSharp {
                                resize(
                                    width: 411
                                    height: 264
                                    cropFocus: CENTER
                                    fit: COVER
                                    background: "rgba(255,255,255,1)"
                                ) {
                                    src
                                }
                            }
                        }
                    }
                }
                ... on DatoCmsPortfolio {
                    __typename
                    titleNode {
                        ...TextHtml
                    }
                    titleTag
                    withSlider
                    showFilter
                    button {
                        ...Button
                    }
                    companies {
                        ...Company
                    }
                    companiesFilter {
                        name
                        slug
                    }
                }
                ... on DatoCmsDeal {
                    __typename
                    titleNode {
                        ...TextHtml
                    }
                    titleTag
                    textNode {
                        ...TextHtml
                    }
                    projects {
                        ...Project
                    }
                    button {
                        ...Button
                    }
                }
                ... on DatoCmsWhyWithPoint {
                    __typename
                    endButtons {
                        ...Button
                    }
                    titleNode {
                        ...TextHtml
                    }
                    textNode {
                        ...TextHtml
                    }
                    buttons {
                        ...Button
                    }
                    image {
                        file {
                            childImageSharp {
                                resize(width: 1280, height: 1440) {
                                    src
                                }
                            }
                        }
                    }
                    imageComplete: image {
                        file {
                            childImageSharp {
                                gatsbyImageData(width: 2000)
                            }
                        }
                    }
                    points {
                        ...Point
                    }
                    lighterBackground
                    fullImage
                }
                ... on DatoCmsWhy {
                    __typename
                    titleNode {
                        ...TextHtml
                    }
                    textNode {
                        ...TextHtml
                    }
                    image {
                        file {
                            childImageSharp {
                                resize(width: 1280, height: 1440) {
                                    src
                                }
                            }
                        }
                    }
                    buttons {
                        ...Button
                    }
                }
                ... on DatoCmsProjectExtract {
                    __typename
                    titleNode {
                        ...TextHtml
                    }
                    textNode {
                        ...TextHtml
                    }
                    buttons {
                        ...Button
                    }
                    logos {
                        file {
                            publicURL
                            childImageSharp {
                                resize(
                                    width: 400
                                    height: 200
                                    cropFocus: CENTER
                                    fit: COVER
                                    background: "rgba(255,255,255,1)"
                                ) {
                                    src
                                }
                            }
                        }
                    }
                }
                ... on DatoCmsAmountExtract {
                    __typename
                    titleNode {
                        ...TextHtml
                    }
                    textNode {
                        ...TextHtml
                    }
                    buttons {
                        ...Button
                    }
                    pretitle
                    graph {
                        file {
                            publicURL
                        }
                    }
                }
                ... on DatoCmsCtaWithImage {
                    __typename
                    titleNode {
                        ...TextHtml
                    }
                    image {
                        file {
                            childImageSharp {
                                resize(width: 1280, height: 1074) {
                                    src
                                }
                            }
                        }
                    }
                    textNode {
                        ...TextHtml
                    }
                    buttons {
                        ...Button
                    }
                }

                ... on DatoCmsHero {
                    __typename
                    titleNode {
                        ...TextHtml
                    }
                    subtitle
                    textNode {
                        ...TextHtml
                    }
                }
                ... on DatoCmsWhyWithPointsSecondary {
                    __typename
                    titleNode {
                        ...TextHtml
                    }
                    image {
                        file {
                            childImageSharp {
                                resize(width: 1280, height: 1280) {
                                    src
                                }
                            }
                        }
                    }
                    points {
                        ...Point
                    }
                }
                ... on DatoCmsMilestoneSection {
                    __typename
                    titleNode {
                        ...TextHtml
                    }
                    subtitle
                    milestones {
                        year
                        image {
                            file {
                                childImageSharp {
                                    resize(width: 1280, height: 1280) {
                                        src
                                    }
                                }
                            }
                        }
                        textNode {
                            ...TextHtml
                        }
                    }
                }
                ... on DatoCmsTeam {
                    __typename
                    titleNode {
                        ...TextHtml
                    }
                    people {
                        ...Person
                    }
                }
                ... on DatoCmsPartner {
                    __typename
                    titleNode {
                        ...TextHtml
                    }
                    textNode {
                        ...TextHtml
                    }
                    logos {
                        file {
                            publicURL
                            childImageSharp {
                                resize(
                                    width: 400
                                    height: 200
                                    cropFocus: CENTER
                                    fit: COVER
                                    background: "rgba(255,255,255,1)"
                                ) {
                                    src
                                }
                            }
                        }
                    }
                    people {
                        ...Person
                    }
                }
                ... on DatoCmsBlockquote {
                    __typename
                    titleNode {
                        ...TextHtml
                    }
                    titleTag
                    quoteNode {
                        ...TextHtml
                    }
                    person {
                        ...Person
                        theImage: image {
                            file {
                                childImageSharp {
                                    resize(width: 740) {
                                        src
                                    }
                                }
                            }
                        }
                    }
                    buttons {
                        ...Button
                    }
                }
                ... on DatoCmsCtaDouble {
                    __typename
                    title1
                    text1Node {
                        ...TextHtml
                    }
                    buttons1 {
                        ...Button
                    }
                    title2
                    text2Node {
                        ...TextHtml
                    }
                    buttons2 {
                        ...Button
                    }
                }
                ... on DatoCmsWhyWithPointsSecondaryDark {
                    __typename
                    titleNode {
                        ...TextHtml
                    }
                    textNode {
                        ...TextHtml
                    }
                    points {
                        ...Point
                    }
                    buttons {
                        ...Button
                    }
                }
                ... on DatoCmsFrequentedAskedQuestion {
                    __typename
                    titleNode {
                        ...TextHtml
                    }
                    textNode {
                        ...TextHtml
                    }
                    questions {
                        ...Faq
                    }
                    buttons {
                        ...Button
                    }
                }
                ... on DatoCmsWhyWithPointsTertiary {
                    __typename
                    image {
                        file {
                            childImageSharp {
                                resize(width: 1280, height: 930) {
                                    src
                                }
                            }
                        }
                    }
                    points {
                        ...Point
                    }
                }
                ... on DatoCmsStep {
                    __typename
                    titleNode {
                        ...TextHtml
                    }
                    steps {
                        ...Point
                    }
                }
                ... on DatoCmsStepSlider {
                    __typename
                    titleNode {
                        ...TextHtml
                    }
                    image {
                        file {
                            childImageSharp {
                                resize(width: 1280, height: 1480) {
                                    src
                                }
                            }
                        }
                    }
                    steps {
                        ...Point
                    }
                }
                ... on DatoCmsCta {
                    __typename
                    pretitle
                    title
                    textNode {
                        ...TextHtml
                    }
                    buttons {
                        ...Button
                    }
                }
                ... on DatoCmsFeaturedBlogSection {
                    __typename
                    post {
                        ...BasicWordpressPost
                    }
                }
                ... on DatoCmsBlogSection {
                    __typename
                    titleNode {
                        ...TextHtml
                    }
                    categories {
                        name
                        slug
                        databaseId
                    }
                    posts {
                        ...BasicWordpressPost
                    }
                }
                ... on DatoCmsResourcesGuidesSection {
                    __typename
                    titleNode {
                        ...TextHtml
                    }
                    guides {
                        requiresLogin
                        isFuture
                        title
                        subtitle
                        file {
                            alt
                            file {
                                publicURL
                            }
                        }
                    }
                }
                ... on DatoCmsResourcesBlogSection {
                    __typename
                    titleNode {
                        ...TextHtml
                    }
                    posts {
                        ...BasicWordpressPost
                    }
                }
                ... on DatoCmsResourcesWebinarsSection {
                    __typename
                    titleNode {
                        ...TextHtml
                    }
                    webinars {
                        requiresLogin
                        isFuture
                        title
                        text
                        slug
                        image {
                            file {
                                childImageSharp {
                                    resize(
                                        width: 1280
                                        height: 830
                                        cropFocus: CENTER
                                    ) {
                                        src
                                    }
                                }
                            }
                        }
                        embed {
                            height
                            provider
                            providerUid
                            width
                        }
                        url
                    }
                }
                ... on DatoCmsResourcesMediumSection {
                    __typename
                    titleNode {
                        ...TextHtml
                    }
                    parentURL
                    posts {
                        title
                        createdAt(formatString: "MMMM DD, YYYY", locale: "es")
                        content {
                            subtitle
                        }
                        finalURL
                        previewImage {
                            childImageSharp {
                                resize(
                                    width: 1280
                                    height: 830
                                    cropFocus: CENTER
                                ) {
                                    src
                                }
                            }
                        }
                    }
                }
                ... on DatoCmsContent {
                    __typename
                    title
                    content
                }
                ... on DatoCmsTitlePageSection {
                    __typename
                    titleNode {
                        ...TextHtml
                    }
                }
                ... on DatoCmsContactForm {
                    __typename
                    titleNode {
                        ...TextHtml
                    }
                }
                ... on DatoCmsNewsletter {
                    __typename
                    alternativeBackground
                }
                ... on DatoCmsContactDetail {
                    __typename
                    titleNode {
                        ...TextHtml
                    }
                    textNode {
                        ...TextHtml
                    }
                    coordinates {
                        latitude
                        longitude
                    }
                }
                ... on DatoCmsDealTestimonialsSection {
                    __typename
                    titleNode {
                        ...TextHtml
                    }
                    titleTag
                    textNode {
                        ...TextHtml
                    }
                    dealTestimonials {
                        title
                        textNode {
                            ...TextHtml
                        }
                        image {
                            file {
                                publicURL
                                childImageSharp {
                                    resize(width: 740) {
                                        src
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        posts: allWpPost(sort: { fields: modified, order: DESC }, limit: 1) {
            distinct(field: modified)
        }
        projects: allDozenProject(
            filter: { status: { in: ["open", "restricted"] } }
        ) {
            distinct(field: internal___contentDigest)
        }
        companies: allDozenCompany(sort: { fields: position, order: DESC }) {
            distinct(field: internal___contentDigest)
        }
    }
`
